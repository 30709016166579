.err{
    color: red;
}
.titlecener{
    width: 100%;
    display: inline-block;
    font-size: 30px;
    text-align: center;
}
.imgicons{
    width: 100px;
    margin-bottom: 10px;
}
.disabtext{
    color: black;
}
.form-control:focus {
    border: 1px solid rgba(71, 164, 71, 0.5);
    background-color: #ffffff;
}
.menuspisok {
    height: 210px;
    overflow: auto;
}
.myimagegdp{
    float: left;
}
.mycontacts{
    float: left;
    float: left;
    width: 75px;
    margin-left: 20px;
}
.widtthgg{
    width: 100%;
    display: inline-block;
}
.gabarest{
    display: inline-block;
    width: 100%;
}